<div *ngIf="WorkflowTypes$ | async as WorkflowTypes">
    <div mat-dialog-title>
        Fehlzeit
    </div>
    <div [formGroup]="form" mat-dialog-content class="flex flex-col">
        <mat-form-field appearance="fill">
            <mat-label>Typ</mat-label>
            <mat-select #workflowType formControlName="type">
                <mat-option *ngFor="let type of WorkflowTypes.ListOfWorkflowTyp" [value]="type.nummer">{{ type.bezeichnung }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex space-x-4">
            <mat-form-field appearance="fill">
                <mat-label>Start-Datum</mat-label>
                <input #startDate formControlName="start" matInput type="date">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>End-Datum</mat-label>
                <input #endDate formControlName="end" matInput type="date">
            </mat-form-field>
        </div>

        <mat-form-field appearance="fill">
            <mat-label>Notiz</mat-label>
            <textarea #note formControlName="note" matInput rows="10"></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="flex justify-between">
        <button mat-raised-button [mat-dialog-close]="null" color="accent">Abbrechen</button>
        <button mat-raised-button [mat-dialog-close]="{
            type: 'fz',
            data: {
                id: data.id,
                bookingType: getWorkflowType(workflowType.value, WorkflowTypes.ListOfWorkflowTyp),
                startDate: startDate.value,
                endDate: endDate.value,
                note: note.value
            }
        }" color="primary">
            <ng-container>
                {{ getSaveButtonText() }}
            </ng-container>
        </button>
    </div>
</div>
